.App {
  /*display: flex;*/
  /*text-align: center;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  min-height: 100vh;
  /*font-size: calc(10px + 2vmin);*/
  /*flex-direction: column;*/
  background-color: rgb(0, 30, 60);
  /*height: 100vh;*/
}

@media only screen and (max-width: 768px) {
  .App {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}


.RadialBox {
  width: 100%;
  /*display: flex;*/
  /*text-align: center;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Chart {
  margin: auto;
  width: 700px;
}

.TopBox {
  width: 100%;
  /*display: flex;*/
  /*text-align: center;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 768px) {
  .TopBox {
    flex-direction: column;
  }
}

.TopTitle {
  font-size: 30px;
  color: #10baf8;
  font-weight: 600;
}

.morningStatusImage {
  height: 100px;
  width: auto;

}
